.Main {
  background-color: #ececec;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
  display: block;
  text-align: center;
}

footer a {
  text-decoration: none;
  color: white;
}

.Link {
  border-bottom: medium solid #B68D4C;
  color: white;
}

footer a:hover{
  border-color: #000045;
}

header .Link:hover {
  border-color: #000045;
  transform: scale(1.1); 
}

.error-message {
  color: red;
  /* font-size: 12px; */
  margin-top: 5px;
}

.HeaderFont {
  font-family: "Montserrat", serif;
  font-style: normal;
}

.BodyFont {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
}

/* ==================Colors */

.PrimBack {
  background-color: #2F5065;
}

.SecBack {
  background-color: #9ABBD0;
}

.TertBack {
  background-color: #B68D4C;
}

.Prim {
  color: #2F5065;
}

.Sec {
  color: #9ABBD0;
}

.Tert {
  color: #B68D4C;
}

/* ==================END of Colors */


/* ==================Responsive */

/* Phone */
@media(max-width: 640px) {

  /* GENERAL */
  .Section {
    margin: 0px 20px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
  }

  label, input, select {
    font-size: 16px;
    font-weight: 500;
  }
  
  /* END GENERAL =================== */

  /* HOME PAGE */

  .ServicesSection {
    margin-top: 10px;
  }

  .ServicesSection p strong {
    font-size: 18px;
  }

  .ServicesTitle {
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .BioArticle {
    display: flex;
    flex-direction: column;
  }

  .BioName {
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    }

  .BioTitle {
    font-size: 19px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .BioImgMobile {
    width: 35%;
    float: left;
    margin-right: 1rem;
    margin-top: 18px;
  }

  .BioImg {
    display: none;
  }
  /* END HOME PAGE ========================*/

  /* CONTACT PAGE ========================*/
  .ContactForm {
    display: flex;
    flex-direction: column;
  }

  .ContactForm label {
    margin-top: 10px;
  }

  .ContactForm textarea {
    height: 150px;
  }

  .ContactForm .FormBtn {
    width: 25%;
    height: 30px;
    background-color: #2F5065;
    color: white;
    border-color: #B68D4C;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /* END CONTACT PAGE ========================*/


  /* HEADER & FOOTER ======================*/

  header {
    display: flex;
    justify-content: space-between;
  }

  header .Link{
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  header article {
    display: flex;
    max-width: 40%;
  }

  header h1 {
    font-size: 20px;
  }

  header div {
    display: flex;
    align-items: flex-end;
  }

  header div .Link {
    margin-right: 20px;
  }

  .Logo {
    width: 70px;
    margin: 20px 10px 0px 10px;
  }

  .Footer {
    padding: 20px;
    color: white;
  }
  /* END HEADER & FOOTER ======================*/

}

/* tablet ================*/ 
@media (min-width: 641px) and (max-width: 1023px) {

  /* GENERAL */
  .Section {
    margin: 0px 20px;
  }

  p {
    font-size: 18px;
    font-weight: 500;
  }

  label, input, select {
    font-size: 18px;
    font-weight: 500;
  }
  
  /* END GENERAL =================== */

  /* HEADER & FOOTER ======================*/

  header {
    display: flex;
    justify-content: space-between;
  }

  header .Link{
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  header article {
    display: flex;
    max-width: 40%;
  }

  header h1 {
    font-size: 28px;
  }

  header div {
    display: flex;
    align-items: flex-end;
  }

  header div .Link {
    margin-right: 20px;
  }

  .Logo {
    width: 100px;
    margin: 20px 10px 0px 10px;
  }

  .Footer {
    padding: 20px;
    color: white;
    display: block;
  }
  
  /* END HEADER & FOOTER ======================*/

  /* HOME PAGE */
  .ServicesSection {
    display: flex;
    flex-direction: column;
    margin-left: 1%;
  }

  .ServicesSection p strong {
    font-size: 20px;
  }

  .ServicesTitle {
    font-size: 25px;
    margin-bottom: 0px;
    margin-top: 5px;
  }

  .BioSection {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content:space-around;
    margin-top: auto;
  }

  .BioArticle {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
  }

  .BioName {
    font-size: 25px;
    margin-bottom: 0px;
    margin-top: 0px;
    }

  .BioTitle {
    font-size: 23px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .BioImgMobile {
    margin-left: 1%;
    width: 25%;
    margin-bottom: 2%;
    float: left;
    margin-right: 1rem;
    margin-top: 18px;
  }

  .BioImg {
    display: none;
  }
  /* END HOME PAGE ========================*/

  /* CONTACT PAGE */

  .ContactSection {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .ContactBody {
    max-width: 45%;
    margin-right: 5%;
  }

  .ContactForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 30px;
  }

  .ContactForm input {
    width: auto;
    height: 30px;
    border-radius: 5px;
    margin: 5px 0px;
  }

  .ContactForm textarea {
    width: auto;
    height: 150px;
    border-radius: 5px;
    margin: 5px 0px;
    resize: vertical;
    max-height: 300px;
  }

  .ContactForm select {
    width: auto;
    height: 36px;
    margin: 5px 0px;
    border-radius: 5px;
    padding: 1px 2px;
    border-width: 2px;
  }

  .ContactForm label {
    margin-top: 10px;
  }

  .ContactForm .FormBtn {
    width: 25%;
    height: 30px;
  }
  .ContactForm .FormBtn {
    background-color: #2F5065;
    color: white;
    border-color: #B68D4C;
  }

  /* END CONTACT PAGE ======================*/
}

/* desktop ================*/
@media(min-width: 1024px) {

  /* GENERAL */
  .Section {
    margin: 20px 100px;
  }

  p {
    font-size: 20px;
    font-weight: 500;
  }

  label, input, select {
    font-size: 20px;
    font-weight: 500;
  }
  
  /* END GENERAL =================== */

  /* HOME PAGE */
  .ServicesSection {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
  }

  .ServicesSection p strong {
    font-size: 23px;
  }

  .ServicesTitle {
    font-size: 40px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .BioSection {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content:space-around;
    margin-top: auto;
  }

  .BioArticle {
    display: flex;
    flex-direction: column;
    max-width: 60%;
    margin-right: 50px;
  }

  .BioName {
    font-size: 40px;
    margin-bottom: 0px;
    margin-top: 0px;
    }

  .BioTitle {
    font-size: 30px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .BioImg {
    margin-left: 80px;
    width: 20%;
  }

  .BioImgMobile {
    display: none;
  }
  /* END HOME PAGE ========================*/

  /* CONTACT PAGE */

  .ContactSection {
    display: flex;
    align-items: center;
  }

  .ContactBody {
    max-width: 50%;
  }

  .ContactForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 50px;
  }

  .ContactForm input {
    width: auto;
    height: 30px;
    border-radius: 5px;
    margin: 5px 0px;
  }

  .ContactForm textarea {
    width: auto;
    height: 150px;
    border-radius: 5px;
    margin: 5px 0px;
    resize: vertical;
    max-height: 300px;
  }

  .ContactForm select {
    width: auto;
    height: 36px;
    margin: 5px 0px;
    border-radius: 5px;
    padding: 1px 2px;
    border-width: 2px;
  }

  .ContactForm label {
    margin-top: 10px;
  }

  .ContactForm .FormBtn {
    width: 25%;
    height: 30px;
  }
  .ContactForm .FormBtn:hover {
    background-color: #2F5065;
    color: white;
  }

  /* END CONTACT PAGE ======================*/

  /* PRIVACY PAGE */

  .PrivSection h2 {
    font-size: 40px;
  }
  /* END PRIVACY PAGE ======================*/

  /* HEADER & FOOTER ======================*/

  header {
    display: flex;
    justify-content: space-between;
  }

  header .Link{
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    /* margin-right: 20px; */
    margin-bottom: 10px;
  }

  header article {
    display: flex;
    /* max-width: 40%; */
  }

  header h1 {
    font-size: 36px;
  }

  header div {
    display: flex;
    align-items: flex-end;
  }

  header div .Link {
    margin-right: 20px;
  }

  .Logo {
    width: 100px;
    margin: 20px 20px 0px 25px;
  }

  .Footer {
    padding: 20px;
    color: white;
  }
/* END HEADER & FOOTER ======================*/
}

/* ==================END of Responsive */